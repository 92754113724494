/* custom css rule for price right side tooltip */
p.price span.has-tip.rightToolTip {
	display: inline-block;
	width: 15px;
	height: 15px;
	line-height: 10px;
	text-align: center;
	vertical-align: middle;

	i {
		font-size: 14px;
		color: $gray-text-default;
	}
}

.popover, .tooltip {
	z-index: 1000;
	font-size: 10px;

	ul.pricetooltip {
		padding: 0;
		margin: 0 0 0 10px;
		text-align: left;
	}
}

.popover.customPopover .closeBtn {
	display: none;
}

@include media-breakpoint-down(md) {
	&.customPopoverOpened {
		overflow: hidden;
	}

	.popover.customPopover {
		&:not(.ticketInformationBanner):not(.customExtraInfoPopover):not(.languageSelectorPopover) {
			position: fixed !important;
			top: 0px !important;
			left: 0px !important;
			z-index: 20;
			width: 100%;
			max-width: 100%;
			height: 100%;
			padding: 13px;
			margin: 0;
			background: rgba(0, 0, 0, .5) !important;
			border: none !important;
			border-radius: unset !important;
			transform: unset !important;
			will-change: unset !important;

			.arrow {
				display: none;
			}

			.closeBtn {
				position: absolute;
				top: 2px;
				right: 2px;
				display: block;
				width: 26px;
				height: 26px;
				text-align: center;
				cursor: pointer;
				background: $white;
				border: 3px solid $black;
				border-radius: 13px;

				i {
					font-size: 18px;
					line-height: 20px;
				}
			}

			.popover-content {
				margin: 0;
				background: $white;
				border: 2px solid $black;
				border-radius: 0.3rem;

				.popover-header, .popover-body {
					background-color: transparent;
				}

				.popover-header {
					border: none;

					&::before {
						display: none;
					}
				}

				.popover-body {
					padding: 1.5rem 1.75rem;
				}
			}
		}
	}
}

/* custom popover for arrangement page official ticket */
@import "ticket-information-banner";

@include ticketInformationBannerPopover();

/* custom extra info popover for arrangement page */
@import "custom-extra-info-popover";

@include customExtraInfoPopover();