$homepage-gf-headline-color: $primary-blue !default;

.text {
	margin: 8% 0 0 0;

	@include media-breakpoint-down(md) {
		margin: 0;
	}

	h3 {
		@include sf_pro_textbold;
		font-size: 22px;
		color: $homepage-gf-headline-color;
	}

	h3, p {
		margin: 0 0 40px 0;

		@include media-breakpoint-down(lg) {
			margin: 0 0 20px 0;
		}
	}

	a.centeredCtaButton {
		display: inline-block;
		width: auto;

		@include media-breakpoint-down(lg) {
			display: block;
			max-width: 405px;
		}
	}
}

@include media-breakpoint-down(lg) {
	.img, .text h3, .text p {
		text-align: center;
	}

	.text {
		h3 {
			margin: 0 0 35px 0;
		}

		p {
			margin: 35px 0;
		}
	}
}