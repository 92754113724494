$mediaPartners-title-color: $primary-blue !default;
$mediaPartners-item-border-color: $gray-placeholder !default;
$mediaPartners-item-hover-border-color: $primary-blue !default;

h2  {
	margin: 60px 0 25px 0;
	font-size: 28px;
	color: $mediaPartners-title-color;
	text-align: center;

	@include sf_pro_textbold;
}

.item {
	display: inline-table;
	flex: 0 0 23%;
	max-width: 23%;
	padding: 10px;
	margin: 1%;
	text-align: center;
	vertical-align: top;
	border: 1px solid $mediaPartners-item-border-color;

	@include media-breakpoint-down(md) {
		flex: 0 0 48%;
		max-width: 48%;
	}

	.cell {
		display: table-cell;
		height: 100px;
		text-align: center;
		vertical-align: middle;

		img {
			width: auto;
			max-width: 85%;
			height: auto;
			max-height: 100px;
			margin: 0 auto;
		}
	}

	&:hover {
		border: 1px solid $mediaPartners-item-hover-border-color;
	}
}

a.centeredCtaButton {
	width: 275px;
	margin-bottom: 95px;
	text-decoration: none;
}