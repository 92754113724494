@import "../../general/import-general-only";

$howItWorks-title-color: $secondary-green !default;
$howItWorks-dashed-line-path: 'themes/base/img/how-it-works-pattern/how-it-works-pattern.png' !default;
$howItWorks-step-background: $secondary-green !default;
$howItWorks-step-color: $white !default;
$howItWorks-step-text-color: $secondary-green !default;

$howItWorks-header-color: $text-blue !default;
$howItWorks-header-highlighted-color: $secondary-green !default;
$howItWorks-bg-color: $gray-light !default;
$howItWorks-btn-bg: $tertiary-blue !default;
$howItWorks-btn-bg-hover: $primary-blue !default;


.howItWorks-content {
	position: relative;
	padding: 4em 0;
	overflow: hidden;
	background: $howItWorks-bg-color;

	@include media-breakpoint-down(md) {
		padding: 3em 0 7em 0;
		margin-top: 2em;
	}

	&:before {
		position: absolute;
		bottom: -50%;
		left: -25%;
		z-index: 0;
		width: 50%;
		height: 100%;
		content: '';
		background: radial-gradient(ellipse at center,  rgba(129,197,249,1) 0%,rgba(128,235,177,0.5) 50%,rgba(250,250,250,0.2) 70%,rgba(0,0,0,0) 100%);
		opacity: 0.3;

		@include media-breakpoint-down(md) {
			bottom: -50vw;
			left: -50vw;
			width: 100%;
			height: 100vw;
		}
	}
}

.howItWorks {
	position: relative;
	z-index: 2;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	font-size: 1.2em;
	color: $howItWorks-header-color;

	@include media-breakpoint-down(lg) {
		font-size: 1em;
	}

	@include media-breakpoint-down(md) {
		font-size: 1em;
	}

	.howItWorks-left,
	.howItWorks-right {
		padding: 0;
	}

	.howItWorks-left {
		flex: 0 0 45%;
		max-width: 45%;

		@include media-breakpoint-down(lg) {
			padding-right: 6%;
		}

		@include media-breakpoint-down(md) {
			flex: unset;
			width: 100%;
			max-width: unset;
			margin-bottom: 1em;
		}
	}

	.howItWorks-right {
		flex: 0 0 55%;
		max-width: 55%;

		@include media-breakpoint-down(md) {
			flex: unset;
			width: 100%;
			max-width: unset;
		}
	}

	.howItWorks-header {
		position: relative;
		margin: -5px 20px 15px 0;
		font-size: 2.3em;
		line-height: 1.2em;
		text-align: left;

		@include media-breakpoint-down(md) {
			margin-right: 0;
		}

		@include sf_pro_textmedium;

		B {
			font-weight: normal;
			color: $howItWorks-header-highlighted-color;

			@include sf_pro_textmedium;
		}

		@include media-breakpoint-down(md) {
			font-size: 1.9em;
		}

	}

	.howItWorks-lead {
		margin-right: 20px;
		margin-bottom: 20px;
		line-height: 1.2em;

		BR {
			@include media-breakpoint-down(lg) {
				display: none;
			}
		}

		@include media-breakpoint-down(md) {
			margin-right: 0;
		}
	}

	.howItWorks-btn-wrapper {
		margin: 0;
	}

	.howItWorks-btn {
		position: relative;
		min-width: 55%;
		padding: 0.8em 2.5em 0.8em 0.8em;
		margin: 0;
		font-size: 1em;
		line-height: 1.2em;
		text-align: left;
		background: $howItWorks-btn-bg;
		border: 0;

		&:hover {
			background: $howItWorks-btn-bg-hover;
		}

		@include sf_pro_textmedium;

		I {
			position: absolute;
			top: 2.2em;
			right: 1em;
			display: block;
			width: fit-content;
			height: fit-content;
			font-size: 10px;
			font-weight: normal;

			@include media-breakpoint-down(lg) {
				top: 1.8em;
			}
		}
	}

	.howItWorks-steps {
		position: relative;
		display: block;
		padding-left: 60px;
		margin: 0;
		list-style: none;
		counter-reset: howItWorks-li-counter;

		@include media-breakpoint-down(md) {
			padding: 0;
		}

		li {
			position: relative;
			display: block;
			min-height: fit-content;
			padding: 15px 0;
			margin: 0;
			line-height: 100%;
			counter-increment: howItWorks-li-counter;

			@include media-breakpoint-down(md) {
				padding: 40px 0 0 0;
			}

			@include media-breakpoint-down(md) {
				margin: 30px 0 25px 0;
			}

			&:before {
				position: absolute;
				top: 0;
				bottom: 0;
				left: -60px;
				width: 30px;
				height: 32px;
				margin: auto;
				font-size: 1.8em;
				line-height: 1em;
				text-align: center;
				content: counter(howItWorks-li-counter);

				@include sf_pro_textmedium;

				@include media-breakpoint-down(md) {
					bottom: initial;
					left: 0;
					text-align: left;
				}
			}

			SPAN {
				display: inline-block;
				line-height: 1.2em;
				vertical-align: middle;
			}

			B {
				font-family: sf_pro_textsemibold;
				font-weight: normal;

				@include sf_pro_textmedium;
			}

		}

	}

}


.row:not(.howItWorks) {
	@include media-breakpoint-down(md) {
		display: none;
	}
	margin: 0;

	h2 {
		margin: 75px 0 25px 0;
		font-size: 22px;
		color: $howItWorks-title-color;
		text-align: center;

		@include sf_pro_textbold;
	}

	.steps {
		position: relative;
		padding: 0;
		margin: 0;

		.background {
			position: absolute;
			top: 40px;
			width: 100%;
			height: 5px;
			background-image: url('#{$default_dir}#{$howItWorks-dashed-line-path}');
			background-repeat: repeat-x;
			background-position: left 0;
			background-size: 57px 3px;
		}

		ul {
			max-width: 1170px;
			padding: 0;
			margin: 0 auto;
			list-style: none;

			li {
				display: block;
				float: left;
				width: 20%;
				margin: 0 auto;
				text-align: center;
				vertical-align: top;

				@include media-breakpoint-down(lg) {
					width: 48%;
					margin: 10px auto;

					&:nth-last-child(1) {
						width: 100%;
					}
				}

				.marker {
					position: relative;
					box-sizing: border-box;
					display: inline-block;
					width: 80px;
					height: 80px;
					padding: 10px;
					margin-bottom: 25px;
					line-height: 0.75em;
					color: $howItWorks-step-color;
					background-color: $howItWorks-step-background;
					border-radius: 15px;

					.number {
						margin: 0;
						font-family: myriad-pro, Arial, sans-serif;
						font-size: 20px;
						font-weight: 500;
						color: $howItWorks-step-color;
						text-align: left;
					}

					i {
						margin-top: 5px;
						margin-left: 15px;
						font-size: 40px;
					}

					span {
						&[class*="eventTripsIcon-"] {
							position: absolute;
							right: 10px;
							bottom: 10px;
							font-size: 45px;
						}

						&.eventTripsIcon-ticket-icon {
							font-size: 40px;
						}

						&.eventTripsIcon-hotel-icon {
							right: 15px;
						}

						&.eventTripsIcon-plane-leftToRight {
							right: 15px;
							font-size: 35px;
						}

						.eventTripsIcon-soccer-icon {
							right: 15px;
							font-size: 40px;
						}
					}
				}

				.description {
					text-align: center;

					.title {
						height: 1em;
						margin: 0;
						font-family: myriad-pro, Arial, sans-serif;
						font-size: 14px;
						line-height: 20px;
						color: $howItWorks-step-text-color;
						text-align: center;
						vertical-align: middle;
					}
				}
			}

			&.withoutFlight li {
				width: 25%;
			}
		}
	}
}