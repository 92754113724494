@import "../../base/styles/general/import-general-only";
@import "node_modules/bootstrap/scss/card";
@import "../../base/styles/general/messages/payment-installments-message";
@import "../../base/styles/variables/homepage";

BODY {
	MAIN {
		position: relative;
	}

	.homepage {
		.container {
			margin: 0 auto;

			@include responsiveContainerSizeFixer();
		}

		.allInOneProtectionBlock {
			margin: 25px auto;

			@import "../../base/styles/layout/common/all-in-one-protection";
		}

		@import "../../base/styles/layout/common/payment-installment-promotion";

		.container.offers {
			.row {
				margin: 0;

				@import "../../base/styles/layout/homepage/offers";
			}
		}

		@import "../../base/styles/layout/homepage/lifetime-events";

		.container.giftcard {
			.row {
				margin: 40px 0 0 0;

				@import "../../base/styles/layout/homepage/giftcard";
			}
		}

		.container.popular_events {
			.row {
				margin: 0;

				@import "../../base/styles/layout/homepage/popular-events";
			}
		}

		.container.arrangement_of_the_week {
			margin: 20px auto 0 auto;

			@import "../../base/styles/layout/homepage/arrangement-of-the-week";
		}

		.how_it_works,
		.howItWorks-wrapper {
			@import "../../base/styles/layout/homepage/how-it-works";
		}

		.mobile_newsletter {
			@import "../../base/styles/layout/common/mobile-newsletter";

			@include mobile_newsletter();
		}

		.container.reviews{
			@import "../../base/styles/layout/homepage/reviews";
		}

		.container.calendar {
			padding-bottom: 25px;

			@import "../../base/styles/layout/homepage/calendar";
		}

		@import "../../base/styles/layout/homepage/homepage-faq";
		@import "../../base/styles/layout/homepage/promoted-blog-post";

		.container.media_partners {
			@import "../../base/styles/layout/homepage/media-partners";
		}
	}

	@import "../../base/styles/layout/common/modals/emptymonth-newsletter-modal";
	@import "../../base/styles/layout/common/custom-tooltip-rules";
}