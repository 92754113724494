$customExtraInfoPopover-IconColor: $secondary-green !default;
$customExtraInfoPopover-TitleColor: $primary-blue !default;
$customExtraInfoPopover-mobile-title-background: $primary-blue !default;

@mixin customExtraInfoPopover() {
	.popover.customPopover {
		&.customExtraInfoPopover {
			.popover-body {
				[class*="title"] {
					padding: 8px 0;
					font-size: 14px;
					color: $customExtraInfoPopover-TitleColor;
					text-align: left;

					@include sf_pro_textsemibold;
				}

				ul {
					padding: 0 0 10px 0;
					margin: 0;
					list-style-type: none;

					li {
						position: relative;
						padding: 0 0 0 30px;
						margin: 8px 0;
						font-size: 12px;
						text-align: left;
						list-style-type: none;

						&.dont-display {
							display: none;
						}

						&:before {
							position: absolute;
							top: -1px;
							left: 0;
							z-index: 10;
							display: inline-block;
							width: 18px;
							height: 18px;
							font-family: 'eventTripsIcon';
							font-size: 18px;
							line-height: 18px;
							color: $customExtraInfoPopover-IconColor;
							text-align: center;
							text-indent: 1px;
							content: '\e922';
							border-radius: 25px;
						}
					}
				}
			}
		}


		@include media-breakpoint-down(sm) {
			&.customExtraInfoPopover {
				max-width: unset;
				margin-left: 10px;
				border-radius: 0;

				@media (max-width: 320px) {
					max-width: 290px;
				}

				.arrow {
					display: none;
				}

				.popover-body {
					padding: 0;

					[class*="title"] {
						padding: 10px;
						font-size: 14px;
						color: $white;
						background: $customExtraInfoPopover-mobile-title-background;

						@include sf_pro_textsemibold;
					}

					ul {
						padding: 0;
						margin: 0 10px;
						list-style: none;

						li {
							position: relative;
							padding: 0 0 0 30px;
							margin: 8px 0;
							font-size: 12px;
							text-align: left;
							list-style-type: none;

							&:before {
								position: absolute;
								top: -1px;
								left: 0;
								z-index: 10;
								display: inline-block;
								width: 18px;
								height: 18px;
								font-family: 'eventTripsIcon';
								font-size: 18px;
								line-height: 18px;
								color: $customExtraInfoPopover-IconColor;
								text-align: center;
								text-indent: 1px;
								content: '\e922';
								border-radius: 25px;
							}
						}
					}
				}
			}
		}

		&.customAmendedInfoPopover {
			ul {
				padding: 0 0 5px 0;
				margin: 0;

				li {
					position: relative;
					display: block;
					padding: 4px 0 4px 20px;
					font-size: 12px;
					line-height: 16px;
					vertical-align: top;
					list-style: none;

					@include sf_pro_textlight;

					&:nth-child(odd) {
						padding-right: 5px;
					}

					&:before {
						position: absolute;
						top: 0;
						left: 0;
						z-index: 1;
						margin-left: 0px;
						font-size: 18px;
						line-height: 22px;
						color: $customExtraInfoPopover-IconColor;
						vertical-align: middle;
					}

					&:not([class*="editorIcon"]):before {
						left: 0px;
						font-family: 'eventTripsIcon';
						font-size: 15px;
						content: "\e936";
					}

					&.editorIcon {
						&-other, &-seat, &-drink, &-lunch, &-book {
							&:before {
								/* use !important to prevent issues with browser extensions that change fonts */
								font-family: 'eventTripsIcon' !important;
								font-size: 18px;
								font-style: normal;
								font-weight: normal;
								font-variant: normal;
								line-height: 22px;
								text-transform: none;
								vertical-align: middle;
								speak: none;

								/* Better Font Rendering =========== */
								-webkit-font-smoothing: antialiased;
								-moz-osx-font-smoothing: grayscale;
							}
						}

						&-other:before {
							content: "\e91e";
						}

						&-seat:before {
							content: "\e91a";
						}

						&-drink:before {
							content: "\e91d";
						}

						&-lunch:before {
							content: "\e91c";
						}

						&-book:before {
							content: "\e91b";
						}
					}
				}
			}
		}
	}
}