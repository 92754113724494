$emptyMonthNewsletterModal-title-color: $gray-secondary !default;
$emptyMonthNewsletterModal-footer-border-color: $gray-placeholder !default;

#emptymonthNewsletterModal {
	.modal-title {
		font-size: 15px;
		color: $emptyMonthNewsletterModal-title-color;
	}

	.modal-body {
		padding: 15px;
	}

	.modal-footer {
		padding: 15px;
		text-align: right;
		border-top: 1px solid $emptyMonthNewsletterModal-footer-border-color;
	}
}