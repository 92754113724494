@import "common-messages";
@import "../../variables/general/messages/payment-installments-message";

.commonMessage {
	&-item {

		/**
		Customized message blocks here
		*/

		&.paymentInstallmentsMessage {
			border: 2px solid $paymentInstallmentsMessage-border;

			.commonMessage-item-icon{
				color: $paymentInstallmentsMessage-text;
			}

			.commonMessage-item-text {
				color: $paymentInstallmentsMessage-text;
			}
		}

	}

}
