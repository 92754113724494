@import "../../base/styles/general/settings";
@import "variables/homepage";
@import "../../football/styles/homepage";

BODY .homepage {
	.homepage-static-content {
		padding-bottom: 60px;

		.container {
			A {
				color: $fbr-interactions;
				text-decoration: underline;
			}
		}
	}

	@import "../../base/styles/layout/common/viaplay-banner";

	.viaplayBanner-container {
		margin: 25px 0;

		IMG {
			max-width: 800px;
		}
	}

	.container {
		&.popular_events .row {
			.header {
				background-image: url('#{$default_dir}#{$popularEvents-header-background-img}');
			}

			.clubsContainer .club {
				img, .details {
					border-radius: 5px;
				}

				.details {
					.booking {
						background-color: $fbr-interactions;

						a.button {
							color: $white;
							background-color: transparent;
							border: 2px solid $white;
							border-radius: 5px;
						}
					}
				}

				//&:hover {
				//  .details:before {
				//    display: none;
				//  }
				//}
			}
		}

		&.description {
			h2 {
				color: $fbr-headings;
				text-align: center;
			}

			a {
				color: $fbr-interactions;
			}
		}
	}

}