$calendar-header-background-img: 'themes/base/img/section-header-pattern/section-header-pattern.png' !default;
$calendar-header-title-background: $white !default;
$calendar-header-title-color: $primary-blue !default;

$calendar-item-background: $gray-light !default;
$calendar-item-color: $primary-blue !default;
$calendar-item-hover-background: $primary-blue !default;
$calendar-item-hover-color: $white !default;

.header {
	height: 62px;
	margin: 40px 0;
	line-height: 62px;
	text-align: center;
	background-image: url('#{$default_dir}#{$calendar-header-background-img}');
	background-repeat: repeat-x;
	background-position: center;
	background-size: contain;

	.title {
		display: inline-block;
		width: auto;
		padding: 0 20px;
		margin: 0;
		font-size: 28px;
		color: $calendar-header-title-color;
		background-color: $calendar-header-title-background;

		@include sf_pro_textbold;
	}
}

.row {
	margin: 0;
}

.calendar-box {
	display: inline-block;
	cursor: pointer;

	&:nth-child(even) {
		padding: 5px 0 5px 5px;
	}

	&:nth-child(odd) {
		padding: 5px 5px 5px 0;
	}

	a {
		display: block;
		height: 50px;
		font-size: 20px;
		line-height: 50px;
		color: $calendar-item-color;
		text-align: center;
		text-decoration: none;
		background: $calendar-item-background;
		transition: background 0.3s ease 0s;

		@include sf_pro_textbold;

		&:hover {
			color: $calendar-item-hover-color;
			text-decoration: none;
			background: $calendar-item-hover-background;
			transition: background 0.3s ease 0s;
		}
	}
}