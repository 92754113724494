$promotedBlogPost-title-color: $primary-blue !default;
$promotedBlogPost-categoryName-color: $primary-blue !default;
$promotedBlogPost-categoryName-backgroundColor: $yellow-1 !default;

.promotedBlogPost {
	padding-bottom: 40px;
	text-align: center;

	&-inner {
		margin: 0 auto 20px auto;
	}

	.no-horizontal-padding-on-mobile {
		@include media-breakpoint-down(md) {
			padding-right: 0;
			padding-left: 0;
		}
	}

	h2 {
		margin: 50px 0 25px 0;
		font-size: 28px;
		color: $promotedBlogPost-title-color;
		text-align: center;

		@include sf_pro_textbold;
	}

	.thumbnailImageLink {
		display: inline-block;

		img {
			width: 100%;
			max-height: 400px;
		}
	}

	.content {
		margin: 25px 0 -40px;
		text-align: left;
	}

	&-texts-wrapper, &-img-wrapper {
		display: flex;
		align-items: flex-start;
	}

	&-texts, &-img {
		align-self: center;
	}

	&-title {
		margin: 0;
		text-align: left;

		@include media-breakpoint-down(md) {
			margin-top: 10px;
		}

		.postUrl {
			font-size: 1.2em;
			color: $promotedBlogPost-title-color;

			@include sf_pro_textbold;
		}
	}

	.categoryName {
		display: inline-block;
		float: left;
		padding: 5px 8px;
		margin-bottom: 20px;
		font-size: 12px;
		color: $promotedBlogPost-categoryName-color;
		background-color: $promotedBlogPost-categoryName-backgroundColor;
		border-radius: 5px;

		@include sf_pro_textsemibold;
	}

	.shortDescription {
		margin-top: 15px;
		clear: both;
		font-size: 16px;
	}

	.centeredCtaButton {
		display: inline-block;
		width: auto;
		min-width: 250px;
	}
}