$homepageFaq-title-color: $primary-blue !default;

.homepageFaq {
	margin-bottom: 30px;

	h2 {
		margin: 50px 0 25px 0;
		font-size: 28px;
		color: $homepageFaq-title-color;
		text-align: center;

		@include sf_pro_textbold;
	}

	.homepageFaqAccordion {
		@include sf_pro_textlight;
		font-size: 14px;

		a {
			text-decoration: underline;
		}

		.card {
			margin-top: -2px;
			border-radius: 0;
		}

		.card-header {
			padding: 1rem 1.25rem;
			border-bottom: 0;
		}

		.card-body {
			padding: 25px 40px;
			line-height: 25px;
			border-top: var(--#{$prefix}card-border-width) solid var(--#{$prefix}card-border-color);
		}

		.homepageFaqBtn {
			display: block;
			width: 100%;
			height: auto;
			padding: 0 15px 0 0;
			font-size: 14px;
			color: $body-color;
			text-align: left;
			box-shadow: unset;

			@include sf_pro_textlight;

			&:after {
				position: absolute;
				top: 7px;
				right: 0;
				content: "";
				border-top: 6px solid transparent;
				border-right: 6px solid;
				border-bottom: 6px solid transparent;
				border-left: 0;
			}

			&:focus {
				box-shadow: none;
			}

			&.collapsed {
				&:after {
					border-top: 6px solid;
					border-right: 6px solid transparent;
					border-left: 6px solid transparent;
				}
			}
		}
	}
}
