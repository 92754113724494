@import "../../variables/general/messages/common-messages";

.commonMessage {
	position: relative;
	padding: 0;
	margin: 5px 0 0 0;
	text-align: left;

	&-item {
		position: relative;
		display: inline-block;
		padding: 3px 10px 3px 10px;
		margin: 5px 5px 5px 0;
		overflow: hidden;
		text-align: left;
		vertical-align: top;
		border: 2px solid $commonMessages-defaultBorder;
		border-radius: 5px 5px 5px 5px;

		&.withIcon {
			padding-right: 30px;
		}

		&-icon {
			position: absolute;
			top: 4px;
			right: 7px;
			display: inline-block;
			margin: 0;
			font-size: 17px;
			color: $commonMessages-defaultText;
			vertical-align: middle;

			@include media-breakpoint-down(sm) {
				top: 0.1em;
				font-size: 1.75em;
			}
		}

		&-text {
			position: relative;
			display: inline-block;
			padding-bottom: 2px;
			font-size: 14px;
			line-height: 1.1em;
			color: $commonMessages-defaultText;
			vertical-align: middle;

			@include sf_pro_textsemibold;
		}

	}

}
